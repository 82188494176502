<template>
    <el-dialog
        :title="title ? title : '新增监测点'"
        :visible.sync="visible"
        width="50%"
        center
        destory-on-close
        :close-on-click-modal="false"
    >
        <el-form label-position="top" :model="dataForm" :rules="dataFormRules" ref="dataForm" :disabled="buttonType">
            <el-card class="box-card">
                <el-row :gutter="24">
                    <el-col :span="8">
                        <el-form-item label="监测点名称" prop="name">
                            <el-input v-model="dataForm.name" type="input" size="mini" placeholder="填写监测点名称" />
                        </el-form-item>
                    </el-col>
                    <!-- <el-col :span="8">
                        <el-form-item label="监测设备" prop="euqipment_id">
                            <el-input
                                v-model="dataForm.euqipment_id"
                                type="input"
                                size="mini"
                                placeholder="填写监测设备"
                            />
                        </el-form-item>
                    </el-col> -->
                    <el-col :span="8">
                        <el-form-item label="监测电表编号" prop="ammeterNumber">
                            <el-input
                                v-model="dataForm.ammeterNumber"
                                type="input"
                                size="mini"
                                placeholder="填写监测设备编号"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="监测点类型" prop="monitorSpotTypeId">
                            <el-select v-model="dataForm.monitorSpotTypeId" placeholder="填写监测点类型">
                                <el-option
                                    v-for="item in monitorSpotType"
                                    :key="item.code"
                                    :label="item.name"
                                    :value="item.code"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="监测点地址" prop="address">
                            <el-input v-model="dataForm.address" placeholder="填写监测地址"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="额定电压" prop="nominalVoltage">
                            <el-input v-model="dataForm.nominalVoltage"  placeholder="填写额定电压"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="24">
                    <el-col :span="4">
                        <el-form-item label="最大值" prop="maxValue">
                            <el-input v-model="dataForm.maxValue" type="input" size="mini" placeholder="填写最小值" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="1" style="padding: 0px; margin: 0; width: 0.5%"
                        ><el-form-item style="padding: 0px; margin: 0; width: 50%"
                            ><br />
                            {{ '-' }}</el-form-item
                        ></el-col
                    >
                    <el-col :span="4">
                        <el-form-item prop="minValue" label="最小值">
                            <el-input v-model="dataForm.minValue" type="input" size="mini" placeholder="填写最小值" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="单位" prop="unit">
                            <el-input v-model="dataForm.unit" placeholder="输入单位"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <!-- <el-row :gutter="24"> </el-row> -->
            </el-card>
            <el-card class="box-card" style="margin-top: 20px">
                <div slot="header" class="clearfix">
                    <span>表单扩展</span>
                </div>
                <!-- <strong>*</strong>{{ '关联监测点类型 :  ' }} -->
                <!-- <el-select
                    v-model="dataForm.monitorTypeId"
                    placeholder="请选择"
                    size="mini"
                    style="width: 20%; margin: 0 10px 10px"
                    disabled
                >
                    <el-option v-for="item in monitorType" :key="item.code" :label="item.name" :value="item.code">
                    </el-option>
                </el-select> -->
                <el-row :gutter="24">
                    <el-col :span="24">
                        <el-table border :data="dataForm.dbTable">
                            <el-table-column type="index" width="50" label="序号"> </el-table-column>
                            <el-table-column label="监测类型">
                                <template slot-scope="scope">
                                    <el-form-item
                                        :prop="'dbTable.' + scope.$index + '.monitorTypeId'"
                                        :rules="dataFormRules.dbTable.monitorTypeId"
                                    >
                                        <el-select
                                            v-model="scope.row.monitorTypeId"
                                            placeholder="请选择监测类型"
                                            size="mini"
                                            @change="monitorTypeChange"
                                        >
                                            <el-option
                                                v-for="item in monitorType"
                                                :key="item.code"
                                                :label="item.name"
                                                :value="item.code"
                                            >
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column prop="monitorKey" label="扩展类型字段">
                                <template slot-scope="scope">
                                    <!-- <el-input v-model="scope.row.monitorKey" placeholder="请输入字段"></el-input> -->
                                    <el-form-item
                                        :prop="'dbTable.' + scope.$index + '.monitorKey'"
                                        :rules="dataFormRules.dbTable.monitorKey"
                                    >
                                        <el-cascader
                                            :options="options[scope.row.monitorTypeId]"
                                            v-model="scope.row.monitorKey"
                                            :props="{
                                                checkStrictly: true,
                                                value: 'code',
                                                label: 'name',
                                                key: 'code',
                                                children: 'children',
                                            }"
                                            clearable
                                        ></el-cascader>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column prop="modelValue" label="扩展类型数据">
                                <template slot-scope="scope">
                                    <el-form-item
                                        :prop="'dbTable.' + scope.$index + '.modelValue'"
                                        :rules="dataFormRules.dbTable.modelValue"
                                    >
                                        <el-input v-model="scope.row.modelValue" placeholder="请输入数据"></el-input
                                    ></el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column width="50" label="操作" v-if="!buttonType">
                                <template slot-scope="scope">
                                    <div style="text-align: center">
                                        <i class="el-icon-remove" title="" @click="delProject(scope)"></i>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                        <el-button style="width: 100%" @click="addProject">添加</el-button>
                    </el-col>
                </el-row>
            </el-card>

            <el-card class="box-card" style="margin-top: 20px">
                <div slot="header" class="clearfix">
                    <span>监测点取值扩展</span>
                </div>
                <el-row :gutter="24">
                    <el-col :span="24">
                        <el-table border :data="dataForm.scopeEntityList">
                            <el-table-column type="index" width="50" label="序号"> </el-table-column>
                            <el-table-column prop="objField" label="取值对象">
                                <template slot-scope="scope">
                                    <el-form-item
                                        :prop="'scopeEntityList.' + scope.$index + '.objField'"
                                        :rules="dataFormRules.scopeEntityList.objField"
                                    >
                                        <el-input v-model="scope.row.objField" placeholder="请输入字段名"></el-input
                                    ></el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column prop="valueField" label="取值字段">
                                <template slot-scope="scope">
                                    <el-form-item
                                        :prop="'scopeEntityList.' + scope.$index + '.valueField'"
                                        :rules="dataFormRules.scopeEntityList.valueField"
                                    >
                                        <el-input v-model="scope.row.valueField" placeholder="请输入字段"></el-input>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column prop="valueField" label="取值类型">
                                <template slot-scope="scope">
                                    <el-form-item
                                        :prop="'scopeEntityList.' + scope.$index + '.dataType'"
                                        :rules="dataFormRules.scopeEntityList.dataType"
                                    >
                                        <el-select v-model="scope.row.dataType" placeholder="请输入字段">
                                            <el-option
                                                v-for="item in DataTypeoptions"
                                                :key="item.value"
                                                :label="item.label"
                                                :value="item.value"
                                            >
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column prop="formulaMode" label="计算方式">
                                <template slot-scope="scope">
                                    <el-form-item
                                        :prop="'scopeEntityList.' + scope.$index + '.formulaMode'"
                                        :rules="dataFormRules.scopeEntityList.formulaMode"
                                    >
                                        <el-select v-model="scope.row.formulaMode" placeholder="请选择计算方式">
                                            <el-option
                                                v-for="item in formulaModeOption"
                                                :key="item.value"
                                                :label="item.label"
                                                :value="item.value"
                                            >
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column width="50" label="操作" v-if="!buttonType">
                                <template slot-scope="scope">
                                    <div style="text-align: center">
                                        <i class="el-icon-remove" title="" @click="delProjectScope(scope)"></i>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                        <el-button style="width: 100%" @click="addProjectScope">添加</el-button>
                    </el-col>
                </el-row>
            </el-card>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button v-if="!dataForm.buttonType" type="primary" @click="dataFormSubmit">新建</el-button>
            <el-button v-if="dataForm.buttonType === 'edit'" type="primary" @click="dataFormSubmit">修改</el-button>
            <el-button type="primary" @click="closePage">关闭</el-button>
        </span>
    </el-dialog>
</template>
<script>
import {cloneDeep} from 'lodash';
export default {
    name: 'MintorPiontEdit',
    props: ['monitorType', 'monitorSpotType'],
    data() {
        const formulaModeOption = [
            {
                label: '最大值',
                value: 'MAX',
            },
            {
                label: '最小值',
                value: 'MIN',
            },
            {
                label: '平均',
                value: 'AVERAGE',
            },
        ];
        const DataTypeoptions = [
            {
                label: '字符串',
                value: 'string',
            },
            {
                label: '时间',
                value: 'dateTime',
            },
            {
                label: '数字',
                value: 'double',
            },
        ];
        var validateIsFloat = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入数值'));
            } else {
                if (/^\d+(\.\d+)?$/.test(value)) {
                    callback();
                } else {
                    callback(new Error('请正确输入整数或者浮点数'));
                }
            }
        };
        return {
            buttonType: false,
            visible: false,
            title: '',
            dataForm: {
                name: '', //监测名称
                monitorTypeId: '', //监测类型id
                power: '', //监测电压
                euqipmentId: '', //监测设备
                ammeterNumber: '', //电表编号
                maxValue: '', //额定电压
                minValue: '', //变比
                unit: '', //采集电压单位
                dbTable: [], //类型扩展字段
                scopeEntityList: [], //取值扩展
                extendType: '',
                address: '', //地址
                nominalVoltage: '', //额定电压
            },
            type: [],
            device: [],
            dataFormRules: {
                unit: [
                    {
                        required: true,
                        message: '单位不能为空',
                        trigger: 'blur',
                    },
                ], //采集电压单位
                name: [
                    {
                        required: true,
                        message: '监测名称不能为空',
                        trigger: 'blur',
                    },
                ],
                //额定电压
                nominalVoltage: [
                    {
                        required: true,
                        message: '额定电压不能为空',
                        trigger: 'blur',
                    },
                ],
                //监测地址
                address: [
                    {
                        required: true,
                        message: '监测点地址不能为空',
                        trigger: 'blur',
                    },
                ],
                //监测点类型
                monitorSpotTypeId: [
                    {
                        required: true,
                        message: '监测类型不能为空',
                        trigger: 'blur',
                    },
                ], //监测类型
                ammeterNumber: [
                    {
                        required: true,
                        message: '监测设备不能为空',
                        trigger: 'blur',
                    },
                ], //监测电压最大
                maxValue: [
                    {
                        validator: validateIsFloat,
                        required: true,
                        trigger: 'blur',
                    },
                ], //监测电压最小值
                minValue: [
                    {
                        validator: validateIsFloat,
                        required: true,
                        trigger: 'blur',
                    },
                ],
                dbTable: {
                    monitorTypeId: [
                        {
                            required: true,
                            message: '监测类型不能为空',
                            trigger: 'blur',
                        },
                    ],
                    monitorKey: [
                        {
                            required: true,
                            message: '类型字段不能为空',
                            trigger: 'blur',
                        },
                    ],
                    modelValue: [
                        {
                            required: true,
                            message: '类型数据不能为空',
                            trigger: 'blur',
                        },
                    ],
                },
                scopeEntityList: {
                    objField: [
                        {
                            required: true,
                            message: '对象对象不能为空',
                            trigger: 'blur',
                        },
                    ],
                    formulaMode: [
                        {
                            required: true,
                            message: '计算方式不能为空',
                            trigger: 'blur',
                        },
                    ],
                    valueField: [
                        {
                            required: true,
                            message: '取值字段不能为空',
                            trigger: 'blur',
                        },
                    ],
                    dataType: [
                        {
                            required: true,
                            message: '取值类型不能为空',
                            trigger: 'blur',
                        },
                    ],
                },
            },
            dbTable: [],
            dbTableFields: ['扩展字段', '扩展字段数据'],
            options: {},
            formulaModeOption,
            delList: {
                affiliateIds: [],
                scopeIds: [],
            },
            DataTypeoptions,
        };
    },
    methods: {
        getIsShow(item) {
            let index = this.dataForm.ranges ? this.dataForm.ranges.indexOf(item) : -1;
            if (index > -1) {
                return true;
            }
        },
        init(data) {
            if (data) {
                this.dataForm = data;
                this.dataForm.dbTable = [];
                //创建需要删除的列表
                this.delList = {
                    affiliateIds: [],
                    scopeIds: [],
                };
                if (data.monitorTypeId) {
                    let monitorTypes = data.monitorTypeId.split(',');
                    monitorTypes.pop();
                    //遍历循环类型数组
                    monitorTypes.forEach((monitorType) => {
                        //根据类型取出 dbTable
                        data.monitorTypeMap[monitorType]?.forEach((item) => {
                            let name = item.monitorKey;
                            this.monitorTypeChange(monitorType, false);
                            let index = []; // 树中的列表数据
                            let func = this.serach(name, index); //递归树
                            func(this.monitorType);
                            if (index.length > 1) {
                                //将第一个值树头删除
                                for (let k = 0; k < index.length - 1; k++) {
                                    index[k] = index[k + 1];
                                }
                                index.pop();
                            }
                            let obj = {
                                id: item.id,
                                monitorTypeId: monitorType, //监测类型
                                monitorKey: '',
                                modelValue: '',
                            };
                            //修改扩展字段的数据
                            obj.monitorKey = index;
                            obj.modelValue = item.modelValue;
                            this.dataForm.dbTable.push(obj); //将监测类型的数据都加入到dbTable中
                        });
                    });
                }
                switch (data.buttonType) {
                    case 'view':
                        this.title = '查看监测点';
                        this.buttonType = true;
                        break;
                    case 'edit':
                        this.title = '编辑监测点';
                        this.buttonType = false;
                        break;
                }
            } else {
                this.dataForm = {
                    name: '', //监测名称
                    monitorTypeId: '', //监测类型id
                    power: '', //监测电压
                    euqipmentId: '', //监测设备
                    ammeterNumber: '', //电表编号
                    maxValue: '', //额定电压
                    minValue: '', //变比
                    unit: '', //采集电压单位
                    dbTable: [],
                    scopeEntityList: [], //取值扩展
                };
                this.buttonType = false;
            }
            this.visible = true;
            this.$nextTick(() => {
                this.$refs.dataForm.clearValidate();
            });
        },
        dataFormSubmit() {
            let data = this.dataForm;
            this.$refs.dataForm.validate((valid) => {
                if (valid) {
                    if (data.dbTable.length == 0) {
                        this.$message({
                            message: '请至少填写类型扩展表单的一条数据',
                            type: 'warning',
                        });
                        return;
                    }
                    if (data.scopeEntityList.length == 0) {
                        this.$message({
                            message: '请至少填写取值扩展表单的一条数据',
                            type: 'warning',
                        });
                        return;
                    }
                    // debugger;
                    let cache = [];
                    let isCheck = true;
                    data.scopeEntityList.forEach((scope) => {
                        // 取值字段识别去重
                        if (!cache.includes(scope.valueField)) {
                            cache.push(scope.valueField);
                        } else {
                            this.$message({
                                message: scope.valueField + '取值字段不能重复',
                                type: 'error',
                            });
                            isCheck = false;
                        }
                    });
                    if (!isCheck) return;
                    let monitorTypes = data.dbTable.map((item) => item.monitorTypeId);
                    if (monitorTypes.length > 0) {
                        let monitorTypeList = new Set(monitorTypes);
                        data.monitorTypeId = '';
                        data['monitorTypeMap'] = {}; //创建类型对应扩展的列表
                        monitorTypeList.forEach((monitorType) => {
                            let tables = data.dbTable.filter((table) => table.monitorTypeId === monitorType);
                            data['monitorTypeMap'][monitorType] = cloneDeep(tables);
                            data['monitorTypeMap'][monitorType]?.forEach((e) => {
                                if (e.monitorKey) {
                                    e.monitorKey = e.monitorKey[e.monitorKey.length - 1]; //取最后一个id
                                }
                            });
                            data.monitorTypeId += monitorType + ',';
                        });

                        data.monitorTypeId = data.monitorTypeId.slice(0, data.monitorTypeId.length);
                    }
                    // debugger;
                    data['powerMonitorVO'] = this.delList;
                    let func;
                    if (data.id) {
                        func = this.$client.powerMonitorUpdate(data.id, data);
                    } else {
                        data.createUserName = this.$store.state.userInfo.realName;
                        func = this.$client.powerMonitorAdd(data);
                    }
                    func.then((res) => {
                        delete data.dbTable;
                        if (res.code === 0) {
                            this.$message({
                                message: '新建成功',
                                type: 'success',
                            });
                            this.closePage();
                            this.$emit('refreshDataList');
                        }
                    });
                }
            });
        },
        //关闭页面
        closePage() {
            this.visible = false;
            this.buttonType = false;
        },
        delProject(data) {
            this.dataForm.dbTable.splice(data.$index, 1);
            // debugger
            if (data.row.id) {
                this.delList.affiliateIds.push(data.row.id);
            }
        },
        delProjectScope(data) {
            this.dataForm.scopeEntityList.splice(data.$index, 1);
            if (data.row.id) {
                this.delList.scopeIds.push(data.row.id);
            }
        },
        addProject() {
            let item = {
                monitorTypeId: '',
                monitorKey: '',
                modelValue: '',
            };
            this.dataForm.dbTable.push(item);
        },
        addProjectScope() {
            let item = {
                dataType: '',
                valueField: '',
                formulaMode: '',
                objField: '',
            };
            this.dataForm.scopeEntityList.push(item);
        },
        monitorTypeChange(data, isChar = true) {
            // if (isChar) {
            //     this.dataForm.dbTable = [];
            // }
            let list = this.monitorType.filter((item) => item.code === data);
            // debugger
            if (list && list.length > 0) {
                this.options[data] = list[0].children;
            }
        },
        serach(name, indexList) {
            //递归遍历一颗树，并依次返回他的层级
            return function find(list) {
                // debugger
                for (let i = 0; i < list.length; i++) {
                    let item = list[i];
                    if (item.code === name) {
                        indexList.push(item.code);
                        return indexList;
                    }

                    if (item.children && item.children.length > 0) {
                        indexList.push(item.code);
                        let data = find(item.children);
                        if (data && data[data.length - 1] === name) {
                            return data;
                        }
                    }
                }
                // debugger
                indexList.pop();
                return indexList;
            };
        },
    },
};
</script>
<style lang="less" scoped>
.box-card-voltage .el-form-item {
    margin: 1.5em 0 0.8em 1.5em;
}
.box-card-freque .el-form-item {
    margin: 1.5em 0 0.8em 1.5em;
}
strong {
    color: #f56c6c;
    margin-right: 0.4em;
    opacity: 0.9;
}
/deep/.el-button.is-disabled {
    background-color: #0b1020;
    border-color: #0b1020;
}
</style>
